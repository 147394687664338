.sectionMboutMe{
  height: 644px;
  color: white;
  
  position: relative;
  overflow: hidden;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 70px;
  padding-bottom: 70px;
  border-bottom: 1px solid #585d65;
  z-index: 12;

  margin: 0;
  padding-bottom: 0;
  overflow: visible;
  height: 100vh;
  z-index: 11;
}

.titleName{
  margin: 100px 0 45px -11vw;
  padding: 2.2vw 5.2vw;
  font-size: 4.2vw;
  line-height: 1.2;
  font-weight: 800;
  /* color: #fff; */
  color: #585d65;
  /* background: #6c757d; */
  background: #fff;
  word-break: break-word;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  border-radius: 264px;
  -moz-border-radius: 264px;
  -webkit-border-radius: 264px;
  -khtml-border-radius: 264px;
}

.sectionMboutMe h4{
  margin-bottom: 15px;
  font-size: 22px;
  color: #37d137;
  text-transform: uppercase;
}

.sectionMboutMe p{
  text-align: justify;
  font-size: 16px;
}

.sectionMboutMe .logoSocial {
  background-color: #42454b;
  padding: 15px;
  display: inline-block;
  border-radius: 10px;
}

.sectionMboutMe .logoSocial a{
  color: white;
  margin-right: 15px;
  font-size: 18px;
  transition: ease-in;
  transition-duration: 0.3s;
  font-size: 22px;
}

.sectionMboutMe .logoSocial a:hover{
  color: #37d137;
  transition: ease-out;
  transition-duration: 0.4s;
}



@media (max-width: 720px){
  .sectionMboutMe {
    margin: 0;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 70px;
    height: auto !important;
    text-align: center;
  }
  .sectionMboutMe p{
    padding-top: 30px;
  }
  
  .sectionMboutMe h4{
    padding-top: 20px;
  }

  .titleName{
    margin: unset;
    margin-top: -120px;
    margin-bottom: 20px;
    padding: 30px 25px;
    width: auto;
    font-size: 30px;
  }
}