.wrapper{
  position: relative;
  margin-top: 50px;
  margin-left: 45%;
  margin-right: 92px;
}

.wrapper:before {
  content: '';
  position: fixed;
  left: 45%;
  right: 92px;
  top: 0;
  height: 70px;
  background: #2b2b2b;
  z-index: 13;
  width: 100%;
}

.blockWrapperImage{
  position: fixed;
  top: 0;
  left: 0;
  width: 45%;
  height: 100%;
  background: #2b2b2b;
}

.backgroundCircleEffect{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: #68e0cf;
  clip-path: circle(75% at 10% 45%);
  -webkit-clip-path: circle(75% at 10% 45%);
}

.blockBckgImage{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url('https://images.unsplash.com/photo-1646559534649-e17843577b83?q=80&w=2400&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
}


/* Small devices (landscape phones, 576px and up) */
@media (max-width: 720px){
  .wrapper{
    position: relative;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .wrapper:before {
    content: unset;
  }
  .blockWrapperImage {
    height: 450px;
    position: relative;
    width: 100%;
  }
  .backgroundCircleEffect {
    clip-path: circle(100% at 50% 0%);
    -webkit-clip-path: circle(100% at 50% 0%);
  }
}