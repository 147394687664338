.experienceLine{
  margin-bottom: 50px;
}

.experienceLine .date{
  padding: 5px;
  color: rgb(55, 209, 55);
  border: 1px solid rgb(18, 173, 18);
  margin-bottom: 10px;
  display: inline-block;
}

.experienceLine h5{
  font-weight: 200;
  font-style: italic;
}

.experienceLine ul{
  padding-left: 15px;
}

.experienceLine ul li{
  text-align: justify;
  font-size: 14px;
}

.experienceLine p{
  text-align: justify;
  font-size: 14px;
}